'use client'

import { Button, Spacer } from '@vinted/web-ui'

import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { ROOT_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import useRefUrl from 'hooks/useRefUrl'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { clickEvent } from '_libs/common/event-tracker/events'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { acceptTermsAndConditions, skipTermsAndConditions } from 'data/api'

type Props = {
  canSkip: boolean
}

const TermsActions = ({ canSkip }: Props) => {
  const breakpoints = useBreakpoint()
  const refUrl = useRefUrl(ROOT_URL)
  const { track } = useTracking()
  const { user } = useSession()

  const translate = useTranslate('terms_and_conditions.actions')
  const isRestrictedByTerms = user?.restricted_by_terms

  const buttonSize = breakpoints.tabletsUp ? undefined : Button.Size.Medium
  const screen = isRestrictedByTerms ? Screen.TermsAndConditionsForced : Screen.TermsAndConditions

  const trackClick = (target: ClickableElement) => {
    track(clickEvent({ target, screen, env: 'js' }))
  }

  const handleSkip = async () => {
    trackClick(ClickableElement.SkipTerms)
    const response = await skipTermsAndConditions()
    if ('errors' in response) return

    navigateToPage(refUrl)
  }

  const handleAcceptOnTerms = async () => {
    trackClick(ClickableElement.AcceptTerms)
    if (!user?.id) return

    const response = await acceptTermsAndConditions(user.id)
    if ('errors' in response) return

    navigateToPage(refUrl)
  }

  const renderSkipButton = () => {
    if (isRestrictedByTerms || !canSkip) return null

    return (
      <Button
        text={translate('skip')}
        styling={Button.Styling.Flat}
        size={buttonSize}
        onClick={handleSkip}
        testId="terms-button-skip"
      />
    )
  }

  return (
    <SeparatedList
      separatorAtBreakpoint={{
        tablets: <Spacer size={Spacer.Size.Regular} />,
        desktops: <Spacer size={Spacer.Size.Large} />,
      }}
    >
      <Button
        text={translate('agree')}
        styling={Button.Styling.Filled}
        size={buttonSize}
        onClick={handleAcceptOnTerms}
        testId="terms-button-agree"
      />
      {renderSkipButton()}
    </SeparatedList>
  )
}

export default TermsActions
